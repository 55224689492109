<template>

  <div>

    <!-- loading state -->
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading"></b-spinner>
      </div>
    </template>

    <template v-else>

      <!-- empty state -->
      <template v-if="isEmptyDocuments">

        <b-card class="text-center">

          <feather-icon
            icon="PaperclipIcon"
            size="100"
            class="mr-50 mb-3 mt-2"
          />
          <h2>Sin documentos adjuntos</h2>
          <p class="p-2">
            No se han adjuntado documentos a esta solicitud aún. Por favor, ten en cuenta que para continuar con el proceso, es necesario adjuntar los documentos requeridos.
          </p>







        </b-card>

      </template>

      <!-- data section -->
      <template v-else>

        <b-list-group class="mb-2">
          <b-list-group-item
            class="d-flex justify-content-between"
            v-for="document in documentsList" :key="document.docId"
          >
            <span>{{ document.docName }}</span>
            <b-button
              variant="link"
              class="p-0 text-danger"
              :disabled="isBusy"
              @click="handleDeleteFile(document.docId)"
              v-if="!isReadOnly"
            >
              <feather-icon
                icon="Trash2Icon"
                size="20"
              />
            </b-button>
          </b-list-group-item>
        </b-list-group>



      </template>


      <template v-if="!isReadOnly">
        <!-- upload section -->
        <b-form-file
            v-model="attachFile"
            :state="Boolean(attachFile)"
            accept="application/pdf"
            placeholder="Seleccionar archivo"
            browse-text="Explorar"
        />

        <b-row class="py-1">
          <b-col>
            <b-button
                variant="secondary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="!hasFileLoaded"
                @click="handleResetUpload"
            >
              Restablecer
            </b-button>
            <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="!hasFileLoaded || isBusy"
                @click="handleSendFile"
            >
              Adjuntar archivo
            </b-button>
          </b-col>
        </b-row>
      </template>


      <validation-errors v-if="errors" :errors="errors"/>


    </template>

  </div>

</template>


<script>

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BFormFile
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'
import { getUserData } from '@/modules/auth/utils'
export default {
  name: 'SolicitudCreacionEditDocuments',
  props: {
    frmId: {
      type: Number,
      required: true,
      default: 0
    },
    isReadOnly: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormFile,
    ValidationErrors,
    ToastificationContent
  },
  data(){
    return {

      errors: [],

      isLoading: false,
      isBusy: false,

      documentsList: [],
      attachFile: null
    }
  },
  computed: {

    //
    loggedInUserId(){
      const user = getUserData()
      return user ? user.id : null
    },

    //
    isEmptyDocuments(){
      return this.documentsList.length === 0
    },

    //
    hasFileLoaded(){
      return Boolean(this.attachFile)
    }
  },
  methods: {

    ...mapActions('file-module', ['createDocument', 'fetchDocuments', 'deleteDocument']),

    //procesar envio de archivo
    async handleSendFile(){

      const formData = new FormData()
      formData.append('userId',this.loggedInUserId)
      formData.append('frmId', this.frmId)
      formData.append('file', this.attachFile)

      try {

        this.errors = []
        this.isBusy = true

        await this.createDocument( formData )

        await this.loadData()

        this.attachFile = null

        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Archivo guardado!',
            icon: 'CheckIcon',
            text: 'El archivo se ha guardado correctamente.',
            variant: 'success'
          },
        })

        this.isBusy = false

      } catch (error) {

        if ( error.response.status === 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }

      } finally {

        this.isBusy = false

      }



    },

    // procesar eliminación de archivo
    async handleDeleteFile( id ){

      try {

        this.isBusy = true

        await this.deleteDocument( id )

        await this.loadData()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Archivo eliminado!',
            icon: 'CheckIcon',
            text: 'El archivo se ha eliminado correctamente.',
            variant: 'success'
          },
        })

        this.isBusy = false

      } catch (error) {

        console.log("UNEXPECTED", error)

      } finally {

      }

    },

    // procesar reset upload
    handleResetUpload(){
      this.errors = []
      this.attachFile = null
    },

    //cargar documentos
    async loadData(){

      try {

        this.isLoading = true

        this.documentsList = await this.fetchDocuments({ frmId: this.frmId })

        this.isLoading = false

      } catch (error) {

      } finally {

      }

    }
  },

  async created() {
    await this.loadData()
  }
}


</script>


<style scoped lang="scss">

</style>
